<template>
  <section>
    <p class="text--secondary">Select which notifications you would like to receive.</p>
    <form @submit.prevent="submit">
      <v-card class="mb-16" :rounded="24">
        <v-list class="py-0">
          <template v-for="(item, idx) in notificationOptions">
            <v-list-item
              :key="item.key"
              button
              outlined
              border
              outline
              @click="() => form[item.key] = !form[item.key]"
              class="py-6 px-8"
            >
              <v-list-item-icon color="primary">
                <v-icon :color="form[item.key] ? 'primary': 'secondary'">
                  mdi-checkbox-marked-circle-outline
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.description }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider
              v-if="idx !== notificationOptions.length - 1"
              :key="`notification-option-${item.key}-divider`"
            />
          </template>
        </v-list>
      </v-card>

      <p class="text-h6 font-weight-bold">Minimum Bid Threshold</p>
      <p>
        Receive notifications only when you receive offers with a value greater than or equal
        to this amount of ETH.
      </p>

      <v-text-field
        v-model.number="form.minimumBidThreshold"
        outlined
        hide-details
        single-line
        type="number"
        class="mb-16 disable-prepend-inner-margin"
      >
        <template v-slot:prepend-inner>
          <input-prepended-currency currency="eth" />
        </template>
      </v-text-field>

      <v-btn
        color="primary"
        x-large
        type="submit"
      >
        Save
      </v-btn>
    </form>
  </section>
</template>

<script>
export default {
  name: 'ProfileNotificationSettingsForm',
  data: () => ({
    form: {
      'item-sold': false,
      'bid-activity': false,
      'price-change': false,
      'auction-expiration': false,
      outbid: false,
      minimumBidThreshold: 0.05,
    },
    notificationOptions: [
      {
        key: 'item-sold',
        title: 'Item Sold',
        description: 'When someone purchased one of your items.',
      },
      {
        key: 'bid-activity',
        title: 'Bid Activity',
        description: 'When someone bids on one of your items.',
      },
      {
        key: 'price-change',
        title: 'Price Change',
        description: 'When an item you made an offer on changes in price.',
      },
      {
        key: 'auction-expiration',
        title: 'Auction Expiration',
        description: 'When a Dutch or English auction you created ends.',
      },
      {
        key: 'outbid',
        title: 'Outbid',
        description: 'When an offer you placed is exceeded by another user.',
      },
    ],
  }),

  methods: {
    submit() {
    },
    clear() {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = false;
      });
    },
  },
};
</script>
